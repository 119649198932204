import BootstrapTable from "react-bootstrap-table-next";
import { RiEdit2Fill } from "react-icons/ri";
import { Offer } from "../../../../../../types";
import { useModalActions } from "../../../../../utils/data";
import AddOffreModal from "../Modals/AddOffreModal";

function UpdateButton({ item }: { item: Offer }) {
  const modalId = `${item.id}_update`;
  const ma = useModalActions(modalId);
  return (
    <>
      <button
        className="btn with-tooltip btn-action-icon"
        data-tooltip-content="Supprimer"
        data-bs-toggle="modal"
        data-bs-target={`#${modalId}`}
        style={{ color: "#ACABAB" }}
      >
        <RiEdit2Fill />
      </button>
      <div
        className="modal fade"
        id={modalId}
        aria-labelledby={`${modalId}Label`}
        aria-hidden="true"
      >
        <AddOffreModal
          item={item}
          onHide={() => {
            ma.hide();
          }}
        />
      </div>
    </>
  );
}

function OffreTable({ data }: { data: Offer[] }) {
  const actionFormatter = (cell: string, row: Offer) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          {/* @ts-ignore */}

          <div className="container-btn-action-icon-modal flex-c gap-1">
            <UpdateButton item={row} />
          </div>
        </div>
      </>
    );
  };
  const columns = [
    {
      dataField: "type",
      text: "Offre",
    },
    {
      dataField: "nom",
      text: "Nom",
    },
    {
      dataField: "price",
      text: "Prix",
      formatter: (cell: string) => `${cell} €`,
    },
    {
      dataField: "action",
      text: "Action",
      style: { textAlign: "center" },
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={data}
        columns={columns}
        bordered={true}
        condensed={false}
        responsive={true}
        wrapperClasses="table-responsive admin-table"
        noDataIndication={() => "No data"}
      />
    </>
  );
}

export default OffreTable;
