import { useNavigate } from "react-router-dom";
import { Offer } from "../../../../../types";
import { useAppSelector, useUser } from "../../../../redux/root";
import "./VersionProOwner.css";

function VersionProOwner() {
  const user = useUser();
  const offers = useAppSelector((s) => s.offers);
  const pro = offers.find((o) => o.type === "Professionnel");
  const pre = offers.find((o) => o.type === "Prémium");
  const navigate = useNavigate();

  const onChoice = (abn: Offer) => {
    navigate("/proprietaire/assistance");
  };
  const proChoiced = user?.abonnement === "Professionnel";
  const preChoiced = user?.abonnement === "Prémium";

  if (!pro || !pre) return null;

  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page bg-white p-3">
        <div className="admin-page-title-header-container">
          <div className="bubble-zone-dash-locataire-requetes-header">
            <h4 className="bubble-zone-dash-header-titre-offre">Nos offres</h4>
          </div>
        </div>
        <div className="col-left-dashboard col-left-dashboard-responsive">
          <div className="row">
            <div className="col-md-4 mb-4">
              <div className="bubble-zone-offre-card-pro">
                <div className="bubble-zone-offre-card-pro-type mb-3">
                  {pro.nom}
                </div>
                <div className="bubble-zone-offre-card-pro-pricing mb-3">
                  €{pro.price}
                  <span>TTC / mois</span>
                </div>
                <div className="bubble-zone-offre-card-pro-accroche mb-4">
                  {pro.description}
                </div>
                <hr />
                <ul className="bubblezone-version-pro-list-item">
                  <li>Référencement </li>
                  <li>Réseau social visible (Facebook)</li>
                  <li>Kit de communication RS </li>
                  <li>Mot clés SEO (3 Max)</li>
                  <li>Accès DATA client PRO</li>
                  <li>Renforcement Google Avis</li>
                </ul>
                <div className="d-flex justify-content-center mb-3 mt-5">
                  <button
                    disabled={proChoiced}
                    onClick={() => onChoice(pro)}
                    className="btn custom-btn-add-theme"
                  >
                    <span className="ps-2">
                      {proChoiced ? "Abonnement actuel" : "Choisir"}
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-8 mb-4">
              <div className="bubble-zone-offre-card-premium">
                <div className="bubble-zone-offre-card-premium-type mb-3">
                  {pre.nom}
                </div>
                <div className="bubble-zone-offre-card-premium-pricing mb-3">
                  €{pre.price}
                  <span>TTC / mois</span>
                </div>
                <div className="bubble-zone-offre-card-premium-accroche mb-4">
                  {pre.description}
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <ul className="bubblezone-version-premium-list-item">
                      <li>Référencement </li>
                      <li>Réseau social visible (au choix)</li>
                      <li>Kit de communication RS </li>
                      <li>Mot clés SEO (9 Max)</li>
                    </ul>
                  </div>
                  <div className="col-md-6 mb-3">
                    <ul className="bubblezone-version-premium-list-item">
                      <li>Accès DATA client PRO </li>
                      <li>Renforcement Google Avis</li>
                      <li>Accès DATA client PREMIUM </li>
                    </ul>
                  </div>
                </div>
                <div className="d-flex justify-content-center mb-3 mt-5">
                  <button
                    disabled={preChoiced}
                    onClick={() => onChoice(pre)}
                    className="btn custom-btn-add-theme-white"
                  >
                    <span className="ps-2">
                      {preChoiced ? "Abonnement actuel" : "Choisir"}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VersionProOwner;
