import React from "react";
import Swal from "sweetalert2";
import { Type, TypeFormData } from "../../../../types";
import { createPrefAsync, updatePrefAsync } from "../../../utils/api/user.api";
import { SWAL_DEFAULT_PROPS } from "../../../utils/data";
import { useFormData } from "../../../utils/form";
import { FormError, SubmitButton } from "../../auth/Login";
import CloseModalBtn from "../../shared/CloseModalBtn";

const AddPrefModal = ({
  item,
  onHide,
}: {
  item?: Type;
  onHide: () => void;
}) => {
  const { errors, setErrors, onChange, onSubmitForm, register } =
    useFormData<TypeFormData>(
      {
        title: item?.title ?? "",
      },
      (Yup) => ({
        title: Yup.string().required().label("Titre"),
      })
    );
  const [loading, setLoading] = React.useState(false);
  const onSubmit = React.useCallback(
    async (data: TypeFormData) => {
      setLoading(true);
      try {
        if (item) {
          await updatePrefAsync(item.id, data);
          onHide();
          setTimeout(() => {
            Swal.fire({
              title: "Succès",
              text: "Préférence modifiée avec succès",
              icon: "success",
              ...SWAL_DEFAULT_PROPS,
            });
          }, 400);
        } else {
          await createPrefAsync(data);
          onHide();
          setTimeout(() => {
            Swal.fire({
              title: "Succès",
              text: `Préférence ajoutée avec succès`,
              icon: "success",
              ...SWAL_DEFAULT_PROPS,
            });
            onChange({
              title: "",
            });
          }, 400);
        }
      } catch (ex) {
        setErrors({ title: "Une erreur est survenue lors de l'ajout" });
        console.log("add error", ex);
      }
      setLoading(false);
    },
    [onHide]
  );
  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header py-2 flex-m">
          <h5 className="admin-modal-title text-start">
            {item ? "Modifier" : "Ajouter"} une préférence
          </h5>
          <CloseModalBtn />
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmitForm(onSubmit);
              }}
            >
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="prenom"
                      className="form-label form-label-modal-custom"
                    >
                      Titre
                    </label>
                    <input
                      type="text"
                      placeholder="Titre"
                      className="form-control form-control-modal-custom"
                      {...register("title")}
                    />
                    <FormError error={errors.title} />
                  </div>
                </div>
              </div>
              <div className="gap-3 flex-r align-center row mt-4">
                <div className="col-md-3">
                  <button
                    type="button"
                    className="btn btn-cancelled"
                    onClick={onHide}
                  >
                    Fermer
                  </button>
                </div>
                <div className="col-md-3">
                  <SubmitButton title="Terminer" loading={loading} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPrefModal;
