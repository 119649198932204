import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../../redux/root";
import HeaderAdmin from "../navBar/HeaderAdmin/HeaderAdmin";
import SidebarAdmin from "../navBar/SidebarAdmin/SidebarAdmin";
import "./AdminLayout.css";
import { FiMenu } from "react-icons/fi";

const isFreeRoute = (route: string) => {
  return route.includes("version-pro-success");
};

const AdminLayout = () => {
  const user = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (!user) {
      console.log("navigate /");
      if (!isFreeRoute(location.pathname)) {
        navigate("/");
      }
    }
  }, [user, location.pathname]);
  const isSuccessRoute = isFreeRoute(location.pathname);
  if (!user && !isSuccessRoute) return null;
  return (
    // <div className="container-fluid body-background-dashboard">
    //   {!!user && <HeaderAdmin />}
    //   <div className="row">
    //     {!!user && (
    //       <div className="nav-container no-view-mobile">
    //         <SidebarAdmin />
    //       </div>
    //     )}
    //     <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-0 h-90vh position-relative page-content-base-container">
    //       <div className="admin-page-container">
    //         {(!!user || isSuccessRoute) && <Outlet />}
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div className="container-fluid body-background-dashboard">
      <div className="row">
        <input type="checkbox" name="nav" id="nav_button"></input>
        <label htmlFor="nav_button" className="on no-view-mobile">
          <FiMenu className="icon-side-menu" />
        </label>
        {!!user && (
          <div className="nav-container no-view-mobile">
            <SidebarAdmin />
          </div>
        )}
        <div className="container-header">
        {!!user && <HeaderAdmin />}
        </div>

        <div className="content-position-after-header px-0 h-90vh position-relative page-content-base-container">
          <div className="admin-page-container-layout admin-page-container-height">
          {(!!user || isSuccessRoute) && <Outlet />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
