import { useState } from "react";
import { BiSearchAlt2 } from "react-icons/bi";
import { FaFileExport } from "react-icons/fa";
import { FiPlusSquare } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../../../redux/root";
import { useModalActions } from "../../../../utils/data";
import ImportExcelModal from "../../../modal/admin/ImportExcelModal";
import ListeBubbleZoneTable from "./Tables/ListeBubbleZoneTable";
import "./ListeBubbleZone.css";

function ListeBubbleZoneV2() {
  const ma = useModalActions("export-modal");
  const [search, setSearch] = useState("");
  const bubbleZones = useAppSelector((s) =>
    s.bubble_zone.filter((f) => {
      const l = search.trim().toLocaleLowerCase();
      if (l.length < 1) return true;
      return `${f.name} ${f.phone} ${f.code}`.toLocaleLowerCase().includes(l);
    })
  );
  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page bg-white p-3">
        <div className="admin-page-title-header-container">
          <div className="bubble-zone-dash-locataire-requetes-header">
            <h4 className="bubble-zone-dash-header-titre">Bubblezones</h4>
          </div>
          <NavLink
            to="/admin/ajouter-bubblezone"
            className="btn btn-add-theme"
          >
            <FiPlusSquare />
            <span className="ps-2">Ajouter une bubblezone</span>
          </NavLink>
        </div>
        <div className="col-left-dashboard col-left-dashboard-responsive">
          <div className="">
            <div className="flex-sb align-items-center mb-4">
              <div className="tab-dash-admin-filter-container w-100">
                <form className="row g-3 tab-dash-admin-filter-form">
                  <div className="col-md-12 custom-filter-input-btn-add-container">
                    <div className="custom-proprietaire-filter-input-container">
                      <input
                        type="text"
                        className="form-control custom-proprietaire-filter-input custom-width-proprietaire-filter-input"
                        id="filtreLogement"
                        placeholder="Recherchez un nom de l’établissement, le code de l’établissement ou le code du gérant"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <span className="custom-proprietaire-filter-input-icon-container">
                        <BiSearchAlt2 className="custom-proprietaire-filter-input-icon" />
                      </span>
                    </div>
                    <div className="content-btn-add-theme flex-r mb-4">
                      
                      <button
                        data-bs-toggle="modal"
                        data-bs-target="#export-modal"
                        className="btn custom-btn-upload ml-2"
                        type="button"
                      >
                        <FaFileExport />
                        <span className="ps-2">Importer un fichier excel</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <ListeBubbleZoneTable data={bubbleZones} />
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id={"export-modal"}
        aria-labelledby={`export-modalLabel`}
        aria-hidden="true"
      >
        <ImportExcelModal onHide={ma.hide} />
      </div>
    </div>
  );
}

export default ListeBubbleZoneV2;
