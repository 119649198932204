import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { AiFillEye } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";
import { RiEdit2Fill } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import { BubbleZone } from "../../../../../../types";
import { deleteBubbleZoneAsync } from "../../../../../utils/api/user.api";
import {
  DefaultPaginationOptions,
  SWAL_DEFAULT_PROPS,
  getBubbleZoneCodePostal,
} from "../../../../../utils/data";
import { TogglebubbleZoneActive } from "../../../../tables/admin/BubbleZoneTable/BubbleZoneTable";
function ListeBubbleZoneTable({ data }: { data: BubbleZone[] }) {
  const onDelete = async (id: string) => {
    Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce bubble zone ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        await deleteBubbleZoneAsync(id);
        Swal.fire({
          icon: "success",
          title: "Bubble zone supprimé avec succés",
          showConfirmButton: false,
          timer: 5000,
          ...SWAL_DEFAULT_PROPS,
        });
      },
      ...SWAL_DEFAULT_PROPS,
    });
  };
  const nameFormatter = (cell: string, row: BubbleZone) => {
    return (
      <div className="table-actions-btn-container-img gap-2 d-flex align-items-center">
        <img
          src={row.image || `https://ui-avatars.com/api/?name=${row.name}`}
          alt="type document"
          className="custom-photo-bubblezone-table"
        />{" "}
        <div className="d-flex flex-column gap-1">
          <span className="ps-2">{row.name}</span>
          <span className="ps-2 edit-bubble-code-span">{row.code}</span>
        </div>
      </div>
    );
  };

  const consulterFormatter = (cell: string, row: BubbleZone) => {
    return (
      <div className="table-actions-btn-container-commandes flex-l">
        <NavLink
          data-tooltip-content="Voir"
          className={"btn btn-action-modal-icon with-tooltip"}
          state={row}
          to={`/admin/bubblezone/${row.id}`}
        >
          <AiFillEye />
        </NavLink>
      </div>
    );
  };

  const editFormatter = (cell: string, row: BubbleZone) => {
    return (
      <div className="table-actions-btn-container-commandes flex-l">
        <NavLink
          data-tooltip-content="Modifier"
          className={"btn btn-action-icon-edit with-tooltip"}
          state={row}
          to={`/admin/modifier-bubblezone`}
        >
          <RiEdit2Fill />
        </NavLink>
      </div>
    );
  };

  const actionFormatter = (cell: string, row: BubbleZone) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            <button
              className="btn with-tooltip btn-action-icon-delete"
              data-tooltip-content="Supprimer"
              onClick={() => onDelete(row.id)}
              style={{ color: "#E53E3E" }}
            >
              <FaTrash />
            </button>
          </div>
        </div>
      </>
    );
  };
  const columns = [
    {
      dataField: "name",
      text: "Nom",
      formatter: (cell: string, row: BubbleZone) => nameFormatter(cell, row),
    },
    {
      dataField: "code_postal",
      text: "Code postal",
      formatter: (cell: string, row: BubbleZone) =>
        getBubbleZoneCodePostal(row),
    },
    {
      dataField: "start_hour",
      text: "Consulter",
      formatter: consulterFormatter,
    },
    {
      dataField: "id",
      text: "Modifier",
      style: { textAlign: "center" },
      formatter: editFormatter,
    },
    {
      dataField: "active",
      text: "Bloquer ?",
      formatter: (cell: string, row: BubbleZone) => (
        <TogglebubbleZoneActive item={row} />
      ),
    },
    {
      dataField: "id",
      text: "Supprimer",
      style: { textAlign: "center" },
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={data}
        columns={columns}
        bordered={true}
        condensed={false}
        responsive={true}
        wrapperClasses="table-responsive admin-table"
        pagination={paginationFactory(DefaultPaginationOptions)}
        noDataIndication={() => "No data"}
      />
    </>
  );
}

export default ListeBubbleZoneTable;
