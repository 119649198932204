import { UserInfos } from "../../../../../../types";
import { isBasic } from "../../../../../utils/data";

function InfosPersonnellesUser({ user }: { user: UserInfos }) {
  const basic = isBasic(user);
  return (
    <div className="bubblezone-dash-admin-section-edit-profil-container py-4">
      <div className="bubblezone-dash-admin-section-edit-profil-item">
        <div className="bubblezone-dash-admin-section-edit-profil-prenom">
          <div className="bubblezone-dash-admin-section-edit-profil-prenom-item-deux">
            Nom
          </div>
        </div>
        <div className="bubblezone-dash-admin-section-edit-profil-btn-container">
          <div className="bubblezone-dash-admin-section-edit-profil-btn-item-deux">
            {user?.pseudo}
          </div>
        </div>
      </div>
      <hr />
      <div className="bubblezone-dash-admin-section-edit-profil-item">
        <div className="bubblezone-dash-admin-section-edit-profil-prenom">
          <div className="bubblezone-dash-admin-section-edit-profil-prenom-item-deux">
          Age
          </div>
        </div>
        <div className="bubblezone-dash-admin-section-edit-profil-btn-container">
          <div className="bubblezone-dash-admin-section-edit-profil-btn-item-deux">
            {user?.age}
          </div>
        </div>
      </div>
      <hr />
      <div className="bubblezone-dash-admin-section-edit-profil-item">
        <div className="bubblezone-dash-admin-section-edit-profil-prenom">
          <div className="bubblezone-dash-admin-section-edit-profil-prenom-item-deux">
            Mail
          </div>
        </div>
        <div className="bubblezone-dash-admin-section-edit-profil-btn-container">
          <div className="bubblezone-dash-admin-section-edit-profil-btn-item-deux">
            {user?.email}
          </div>
        </div>
      </div>
      <hr />
      <div className="bubblezone-dash-admin-section-edit-profil-item">
        <div className="bubblezone-dash-admin-section-edit-profil-prenom">
          <div className="bubblezone-dash-admin-section-edit-profil-prenom-item-deux">
            Téléphone
          </div>
        </div>
        <div className="bubblezone-dash-admin-section-edit-profil-btn-container">
          <div className="bubblezone-dash-admin-section-edit-profil-btn-item-deux">
            {user?.phone}
          </div>
        </div>
      </div>
      <hr />
      {!user?.is_proprietaire && (
        <>
          <div className="bubblezone-dash-admin-section-edit-profil-item">
            <div className="bubblezone-dash-admin-section-edit-profil-prenom">
              <div className="bubblezone-dash-admin-section-edit-profil-prenom-item-deux">
                Mood
              </div>
            </div>
            <div className="bubblezone-dash-admin-section-edit-profil-btn-container">
              <div className="bubblezone-dash-admin-section-edit-profil-btn-item-deux">
                {user?.mood}
              </div>
            </div>
          </div>
          <hr />
        </>
      )}
      {user?.is_proprietaire && (
        <div className="bubblezone-dash-admin-section-edit-profil-item">
          <div className="bubblezone-dash-admin-section-edit-profil-prenom">
            <div className="bubblezone-dash-admin-section-edit-profil-prenom-item-deux">
              Abonnement
            </div>
          </div>
          <div className="bubblezone-dash-admin-section-edit-profil-btn-container">
            <div className="bubblezone-dash-admin-section-edit-profil-btn-item-deux">
              <div
                className={
                  !basic
                    ? "details-user-custom-is-pro"
                    : "details-user-custom-is-basic"
                }
              >
                {user?.abonnement || "Basic"}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default InfosPersonnellesUser;
