/* eslint-disable @typescript-eslint/no-use-before-define */
import Chance from "chance";
import { User } from "firebase/auth";
import {
  DocumentData,
  QuerySnapshot,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import moment from "moment";
import { useCallback, useState } from "react";
import Swal from "sweetalert2";
import {
  AdminDisplay,
  Annonce,
  AnnonceFormData,
  AppConfig,
  AppConfigFormData,
  Argument,
  ArgumentFormData,
  BubbleZone,
  BubbleZoneFormData,
  Chat,
  DateField,
  InToBulle,
  MessageContact,
  MessageContactFormData,
  Mood,
  MoodFormData,
  NoteAvis,
  Offer,
  Subject,
  SubjectFormData,
  Type,
  TypeEtablissement,
  TypeEtablissementFormData,
  TypeFormData,
  UserFormData,
  UserInfos,
  Visite,
} from "../../../types";
import { Display, SWAL_DEFAULT_PROPS } from "../data";
import { PATHS } from "./api";
import {
  uploadBubbleZoneImageAsync,
  uploadMoodImageAsync,
} from "./storage.api";

const firestore = getFirestore();
const functions = getFunctions();

export async function majChats() {
  const docs = await getDocs(collection(firestore, PATHS.note_avis));
  let count = 0;
  docs.forEach((it) => {
    const data = it.data();
    if (!data.dates) {
      updateDoc(doc(firestore, PATHS.note_avis, it.id), {
        dates: getDateFiled(moment(data.date)),
      });
      count++;
    }
  });
  console.log("update", count);
}

export const getDateFiled = (currentDate: moment.Moment) => {
  let cd = moment(currentDate.toDate().getTime());
  if (cd.hours() < 8) {
    cd = cd.subtract(1, "days");
  }
  const date: DateField = {
    date: cd.toDate().getTime(),
    day: cd.format("DD/MM/YYYY"),
    month: cd.format("MM/YYYY"),
    week: cd.startOf("week").format("DD/MM/YYYY"),
    year: cd.year(),
  };

  return date;
};

export async function updateUserAsync(uid?: string, data?: Partial<UserInfos>) {
  if (!uid) return;
  return updateDoc(doc(firestore, PATHS.users, uid), data);
}

export async function updateBubbleZoneAsync(
  uid: string,
  data: BubbleZoneFormData
) {
  if (data.image instanceof File) {
    data.image = await uploadBubbleZoneImageAsync(data.image, uid);
  }
  return updateDoc(doc(firestore, PATHS.bubble_zone, uid), data);
}

export async function createBubbleZoneAsync(data: BubbleZoneFormData) {
  const docRef = collection(firestore, PATHS.bubble_zone);
  if (data.image instanceof File) {
    data.image = await uploadBubbleZoneImageAsync(data.image, docRef.id);
  }
  data.created_at = serverTimestamp();
  return addDoc(docRef, data);
}

export async function createOffer(data: Offer) {
  const docRef = collection(firestore, PATHS.offres);
  return addDoc(docRef, data);
}

export async function updateOffer(id: string, data: Offer) {
  const docRef = doc(firestore, PATHS.offres, id);
  return updateDoc(docRef, data);
}

export async function deleteBubbleZoneAsync(id: string) {
  const docRef = doc(firestore, PATHS.bubble_zone, id);
  return deleteDoc(docRef);
}

export async function createAnnonceAsync(data: AnnonceFormData) {
  const docRef = collection(firestore, PATHS.annonces);
  data.created_at = serverTimestamp();
  data.sent = false;
  return addDoc(docRef, data);
}

export async function deleteAnnonceAsync(id: string) {
  const docRef = doc(firestore, PATHS.annonces, id);
  return deleteDoc(docRef);
}

export function userListener(
  userId: string,
  cb: (dzta: UserInfos | null) => void
) {
  return onSnapshot(doc(firestore, PATHS.users, userId), async (snapshot) => {
    if (snapshot.exists()) {
      const data = snapshot.data();
      cb({
        ...data,
        created_at: data.created_at?.toDate()?.toString(),
        uid: snapshot.id,
      } as UserInfos);
    } else {
      cb(null);
    }
  });
}

export function appListener(cb: (dzta: AppConfig | null) => void) {
  return onSnapshot(doc(firestore, "app", "app"), async (snapshot) => {
    if (snapshot.exists()) {
      const data = snapshot.data();
      cb({
        ...data,
      } as AppConfig);
    } else {
      cb(null);
    }
  });
}

export async function getUserInfos(user: User) {
  const docRef = await getDoc(doc(firestore, PATHS.users, user.uid));
  console.log("doc", docRef.data());
  if (docRef.exists()) {
    return { uid: docRef.id, ...docRef.data() } as UserInfos;
  }
  return null;
}

export function bubbleZoneListener(
  cb: (dzta: BubbleZone[], deleteds: string[]) => void
) {
  return onSnapshot(
    collection(firestore, PATHS.bubble_zone),
    async (querySnapshot) => {
      const bubble_zones: BubbleZone[] = [];
      const deleteds: string[] = [];
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === "removed") {
          deleteds.push(change.doc.id);
        } else {
          const dta: unknown = {
            ...change.doc.data(),
            id: change.doc.id,
            created_at:
              change.doc.data().created_at?.toDate()?.getTime() ||
              new Date().getTime(),
          };
          bubble_zones.push(dta as BubbleZone);
        }
      });
      cb(bubble_zones, deleteds);
    }
  );
}

export function offersListener(cb: (dzta: Offer[]) => void) {
  return onSnapshot(
    collection(firestore, PATHS.offres),
    async (querySnapshot) => {
      const data: Offer[] = [];
      querySnapshot.docChanges().forEach((change) => {
        const dta: unknown = {
          ...change.doc.data(),
          id: change.doc.id,
        };
        data.push(dta as Offer);
      });
      cb(data);
    }
  );
}

export function aonnonesListener(
  cb: (dzta: Annonce[], deleteds: string[]) => void
) {
  return onSnapshot(
    collection(firestore, PATHS.annonces),
    async (querySnapshot) => {
      const annonces: Annonce[] = [];
      const deleteds: string[] = [];
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === "removed") {
          deleteds.push(change.doc.id);
        } else {
          const dta: unknown = {
            ...change.doc.data(),
            id: change.doc.id,
            created_at:
              change.doc.data().created_at?.toDate()?.getTime() ||
              new Date().getTime(),
          };
          annonces.push(dta as Annonce);
        }
      });
      cb(annonces, deleteds);
    }
  );
}

export function adminListener(
  cb: (data: UserInfos[], deleteds: string[]) => void
) {
  const q = query(
    collection(firestore, PATHS.users),
    where("is_admin", "==", true)
  );
  return onSnapshot(q, async (querySnapshot) => {
    const users: UserInfos[] = [];
    const deleteds: string[] = [];
    querySnapshot.docChanges().forEach((change) => {
      if (change.type === "removed") {
        deleteds.push(change.doc.id);
      } else {
        const dta: unknown = {
          ...change.doc.data(),
          uid: change.doc.id,
          created_at:
            change.doc.data().created_at?.toDate()?.getTime() ||
            new Date().getTime(),
        };
        users.push(dta as UserInfos);
      }
    });
    cb(users, deleteds);
  });
}

export function typesListener(cb: (data: Type[], deleteds: string[]) => void) {
  return onSnapshot(
    collection(firestore, PATHS.types),
    async (querySnapshot) => {
      const data: Type[] = [];
      const deleteds: string[] = [];
      querySnapshot.docChanges().forEach((change) => {
        if (change.type !== "removed") {
          const dta = {
            ...change.doc.data(),
            id: change.doc.id,
          };
          data.push(dta as Type);
        } else {
          deleteds.push(change.doc.id);
        }
      });
      cb(data, deleteds);
    }
  );
}

export function typesEtablissementListener(
  cb: (data: TypeEtablissement[], deleteds: string[]) => void
) {
  return onSnapshot(
    collection(firestore, PATHS.type_etablissement),
    async (querySnapshot) => {
      const data: TypeEtablissement[] = [];
      const deleteds: string[] = [];
      querySnapshot.docChanges().forEach((change) => {
        if (change.type !== "removed") {
          const dta = {
            ...change.doc.data(),
            id: change.doc.id,
          };
          data.push(dta as TypeEtablissement);
        } else {
          deleteds.push(change.doc.id);
        }
      });
      cb(data, deleteds);
    }
  );
}

export function createUserFunction(data: UserFormData) {
  const callable = httpsCallable(functions, "createUser");
  return callable(data);
}

export function createProprietaireFunction(data: UserFormData) {
  const callable = httpsCallable(functions, "createProprietaire");
  return callable(data);
}
export function getDashboardData() {
  const callable = httpsCallable(functions, "getDashboard");
  return callable();
}

export type Types = "all" | "day" | "week" | "month" | "year";
export function getDashboardDataByType(date, type: Types) {
  const callable = httpsCallable(functions, "getDashboardByType");
  return callable({ date: date, type: type });
}
export function toggleUserStatus(userId: string) {
  const callable = httpsCallable(functions, "toggleUserStatus");
  return callable(userId);
}
export function deleteUserFunction(id: string) {
  const callable = httpsCallable(functions, "deleteUser");
  return callable(id);
}
type SendResponseMail = {
  email: string;
  subject: string;
  response: string;
};
export function sendResponseContactFunction(data: SendResponseMail) {
  const callable = httpsCallable(functions, "sendResponseContact");
  return callable(data);
}
export function deleteNormalUserFunction(id: string) {
  const callable = httpsCallable(functions, "deleteNormalUser");
  return callable(id);
}

export async function getCgu() {
  const docRef = await getDoc(doc(firestore, PATHS.contents, "cgu"));
  if (docRef.exists()) {
    return docRef.data().content as string;
  }
  return "";
}

export async function updateCgu(content: string) {
  return setDoc(doc(firestore, PATHS.contents, "cgu"), { content });
}

export async function getPtc() {
  const docRef = await getDoc(doc(firestore, PATHS.contents, "ptc"));
  if (docRef.exists()) {
    return docRef.data().content as string;
  }
  return "";
}

export async function updatePtc(content: string) {
  return setDoc(doc(firestore, PATHS.contents, "ptc"), { content });
}

export async function responseMessageContact(id: string, response: string) {
  const item = await getDoc(doc(firestore, PATHS.contacts, id));
  if (item.exists()) {
    const data = item.data() as MessageContact;
    const responses = data.responses
      ? [...data.responses, response]
      : [response];
    await updateMessageContact(id, { responses });
    sendResponseContactFunction({
      email: data.email,
      response: response,
      subject: data.sujet ?? data.message,
    });
  }
  return null;
}
export async function updateMessageContact(
  id: string,
  data: Partial<MessageContact>
) {
  return updateDoc(doc(firestore, PATHS.contacts, id), data);
}

export async function getChatsAsync(userId: string) {
  const data: Chat[] = [];
  const docs1 = await getDocs(
    query(collection(firestore, PATHS.chats), where("from", "==", userId))
  );
  const docs2 = await getDocs(
    query(collection(firestore, PATHS.chats), where("to", "==", userId))
  );

  docs1.forEach((doc) => {
    const dta: unknown = {
      ...doc.data(),
      id: doc.id,
      date: doc.data().date?.toDate()?.getTime() || new Date().getTime(),
    };
    data.push(dta as Chat);
  });
  docs2.forEach((doc) => {
    const dta: unknown = {
      ...doc.data(),
      id: doc.id,
      date: doc.data().date?.toDate()?.getTime() || new Date().getTime(),
    };
    data.push(dta as Chat);
  });
  return data.sort((a, b) => b.date ?? 0 - a.date ?? 0);
}

export async function getChatsByBubbleZoneAsync(bubbleZoneId: string) {
  const data: Chat[] = [];
  const docs = await getDocs(
    query(
      collection(firestore, PATHS.chats),
      where("bubbleZoneId", "==", bubbleZoneId)
    )
  );
  docs.forEach((doc) => {
    const dta: unknown = {
      ...doc.data(),
      id: doc.id,
      date: doc.data().date?.toDate()?.getTime() || new Date().getTime(),
    };
    data.push(dta as Chat);
  });
  return data;
}

type DateFilter = { filter: keyof DateField; value: string | number };

function getDateFieldFromDisplayAndDate(
  display: AdminDisplay,
  date?: moment.Moment
): DateFilter | null {
  if (date) {
    return {
      filter: "day",
      value: getDateFiled(date).day,
    };
  }
  if (display === "1") {
    return {
      filter: "day",
      value: getDateFiled(moment()).day,
    };
  }
  if (display === "7") {
    return {
      filter: "week",
      value: getDateFiled(moment()).week,
    };
  }
  if (display === "30") {
    return {
      filter: "month",
      value: getDateFiled(moment()).month,
    };
  }

  if (display === "365") {
    return {
      filter: "year",
      value: getDateFiled(moment()).year,
    };
  }
  return null;
}

async function getIntoBulle(uid: string, filter?: DateFilter) {
  const data: InToBulle[] = [];
  let docs: QuerySnapshot<DocumentData>;
  if (filter) {
    docs = await getDocs(
      query(
        collection(firestore, PATHS.intobulle),
        where(`dates.${filter.filter}`, "==", filter.value),
        where("bubbleZoneId", "==", uid)
      )
    );
  } else {
    docs = await getDocs(
      query(
        collection(firestore, PATHS.intobulle),
        where("bubbleZoneId", "==", uid)
      )
    );
  }
  docs.forEach((doc) => {
    const dta: unknown = {
      ...doc.data(),
      id: doc.id,
    };
    data.push(dta as InToBulle);
  });
  return data;
}

export async function getIntoBulleByUser(uid: string) {
  const data: InToBulle[] = [];

  const docs = await getDocs(
    query(collection(firestore, PATHS.intobulle), where("userId", "==", uid))
  );

  docs.forEach((doc) => {
    const dta: unknown = {
      ...doc.data(),
      id: doc.id,
    };
    data.push(dta as InToBulle);
  });
  return data;
}

export async function getAllIntoBulleAsync(
  uid: string,
  display: Display,
  date?: moment.Moment
) {
  let data: InToBulle[] = [];
  if (date) {
    data = await getIntoBulle(uid, {
      filter: "day",
      value: getDateFiled(date).day,
    });
  } else {
    if (display === "120") {
      data = [
        ...(await getIntoBulle(uid, {
          filter: "month",
          value: getDateFiled(moment()).month,
        })),
        ...(await getIntoBulle(uid, {
          filter: "month",
          value: getDateFiled(moment().subtract(1, "months")).month,
        })),
        ...(await getIntoBulle(uid, {
          filter: "month",
          value: getDateFiled(moment().subtract(2, "months")).month,
        })),
      ];
    } else if (display === "30") {
      data = await getIntoBulle(uid, {
        filter: "month",
        value: getDateFiled(moment()).month,
      });
    } else {
      data = await getIntoBulle(uid);
    }
  }
  return data;
}

async function getNoteAvis(uid: string, filter?: DateFilter) {
  const data: NoteAvis[] = [];
  let docs: QuerySnapshot<DocumentData>;
  if (filter) {
    docs = await getDocs(
      query(
        collection(firestore, PATHS.note_avis),
        where(`dates.${filter.filter}`, "==", filter.value),
        where("bubbleZoneId", "==", uid)
      )
    );
  } else {
    docs = await getDocs(
      query(
        collection(firestore, PATHS.note_avis),
        where("bubbleZoneId", "==", uid)
      )
    );
  }
  docs.forEach((doc) => {
    const dta: unknown = {
      ...doc.data(),
      id: doc.id,
    };
    data.push(dta as NoteAvis);
  });
  return data;
}

export async function getAllNoteAvisAsync(
  uid: string,
  display: Display,
  date?: moment.Moment
) {
  let data: NoteAvis[] = [];
  if (date) {
    data = await getNoteAvis(uid, {
      filter: "day",
      value: getDateFiled(date).day,
    });
  } else {
    if (display === "120") {
      data = [
        ...(await getNoteAvis(uid, {
          filter: "month",
          value: getDateFiled(moment()).month,
        })),
        ...(await getNoteAvis(uid, {
          filter: "month",
          value: getDateFiled(moment().subtract(1, "months")).month,
        })),
        ...(await getNoteAvis(uid, {
          filter: "month",
          value: getDateFiled(moment().subtract(2, "months")).month,
        })),
      ];
    } else if (display === "30") {
      data = await getNoteAvis(uid, {
        filter: "month",
        value: getDateFiled(moment()).month,
      });
    } else {
      data = await getNoteAvis(uid);
    }
  }
  return data;
}

export async function getAllVisitesAdminAsync(
  display: AdminDisplay,
  date?: moment.Moment
) {
  let docs: QuerySnapshot<DocumentData>;
  const filter = getDateFieldFromDisplayAndDate(display, date);
  if (filter) {
    docs = await getDocs(
      query(
        collection(firestore, PATHS.visites),
        where(`dates.${filter.filter}`, "==", filter.value)
      )
    );
  } else {
    docs = await getDocs(collection(firestore, PATHS.visites));
  }

  return docs.size;
}

export async function getAllEyesContactAdminAsync(
  display: AdminDisplay,
  date?: moment.Moment
) {
  let docs: QuerySnapshot<DocumentData>;
  const filter = getDateFieldFromDisplayAndDate(display, date);
  if (filter) {
    docs = await getDocs(
      query(
        collection(firestore, PATHS.eyes_contacts),
        where(`dates.${filter.filter}`, "==", filter.value)
      )
    );
  } else {
    docs = await getDocs(collection(firestore, PATHS.eyes_contacts));
  }

  return docs.size;
}

export async function getAllChatsAdminAsync(
  display: AdminDisplay,
  date?: moment.Moment
) {
  let docs: QuerySnapshot<DocumentData>;
  const filter = getDateFieldFromDisplayAndDate(display, date);
  if (filter) {
    docs = await getDocs(
      query(
        collection(firestore, PATHS.chats),
        where(`dates.${filter.filter}`, "==", filter.value)
      )
    );
  } else {
    docs = await getDocs(collection(firestore, PATHS.chats));
  }
  return docs.size;
}

export async function getIntoBulleAsync(
  display: AdminDisplay,
  date?: moment.Moment
) {
  const data: InToBulle[] = [];
  let docs: QuerySnapshot<DocumentData>;
  const filter = getDateFieldFromDisplayAndDate(display, date);
  if (filter) {
    docs = await getDocs(
      query(
        collection(firestore, PATHS.intobulle),
        where(`dates.${filter.filter}`, "==", filter.value)
      )
    );
  } else {
    docs = await getDocs(collection(firestore, PATHS.intobulle));
  }
  docs.forEach((doc) => {
    const dta: unknown = {
      ...doc.data(),
      id: doc.id,
    };
    data.push(dta as InToBulle);
  });
  return data;
}

export async function getNoteAvisAsync() {
  const data: NoteAvis[] = [];
  let docs: QuerySnapshot<DocumentData>;
  const filter: DateFilter = {
    filter: "month",
    value: getDateFiled(moment().subtract(1, "months")).month,
  };
  if (filter) {
    docs = await getDocs(
      query(
        collection(firestore, PATHS.note_avis),
        where(`dates.${filter.filter}`, "==", filter.value)
      )
    );
  } else {
    docs = await getDocs(collection(firestore, PATHS.note_avis));
  }
  docs.forEach((doc) => {
    const dta: unknown = {
      ...doc.data(),
      id: doc.id,
    };
    data.push(dta as NoteAvis);
  });
  return data;
}

export function noteAvisListener(
  bubbleZoneId: string,
  cb: (data: NoteAvis[]) => void
) {
  const q = query(
    collection(firestore, PATHS.note_avis),
    where("bubbleZoneId", "==", bubbleZoneId)
  );
  return onSnapshot(q, async (querySnapshot) => {
    const data: NoteAvis[] = [];
    querySnapshot.docChanges().forEach((change) => {
      if (change.type !== "removed") {
        const dta: unknown = {
          ...change.doc.data(),
          id: change.doc.id,
        };
        data.push(dta as NoteAvis);
      }
    });
    cb(data);
  });
}

export function deleteAvis(ids: string[]) {
  const promises = ids.map((id) => {
    const docRef = doc(firestore, PATHS.note_avis, id);
    return deleteDoc(docRef);
  });

  return Promise.all(promises);
}

export function messageContactListener(cb: (data: MessageContact[]) => void) {
  return onSnapshot(
    collection(firestore, PATHS.contacts),
    async (querySnapshot) => {
      const data: MessageContact[] = [];
      querySnapshot.docChanges().forEach((change) => {
        if (change.type !== "removed") {
          const dta: unknown = {
            ...change.doc.data(),
            id: change.doc.id,
            created_at:
              change.doc.data().created_at?.toDate()?.getTime() ||
              new Date().getTime(),
          };
          data.push(dta as MessageContact);
        }
      });
      cb(data);
    }
  );
}
export function usersListener(
  cb: (data: UserInfos[], deleteds: string[]) => void
) {
  return onSnapshot(
    collection(firestore, PATHS.users),
    async (querySnapshot) => {
      const data: UserInfos[] = [];
      const deleteds: string[] = [];
      querySnapshot.docChanges().forEach((change) => {
        if (change.type !== "removed") {
          const dta: unknown = {
            deleted: false,
            ...change.doc.data(),
            uid: change.doc.id,
          };
          const item = dta as UserInfos;
          if (!item.deleted) {
            data.push(item);
          } else {
            deleteds.push(change.doc.id);
          }
        } else {
          deleteds.push(change.doc.id);
        }
      });
      cb(data, deleteds);
    }
  );
}

export function subjectsListener(
  cb: (data: Subject[], deleteds: string[]) => void
) {
  return onSnapshot(
    collection(firestore, PATHS.subjects),
    async (querySnapshot) => {
      const data: Subject[] = [];
      const deleteds: string[] = [];
      querySnapshot.docChanges().forEach((change) => {
        if (change.type !== "removed") {
          const dta: unknown = {
            ...change.doc.data(),
            id: change.doc.id,
            created_at:
              change.doc.data().created_at?.toDate()?.getTime() ||
              new Date().getTime().toString(),
          };
          data.push(dta as Subject);
        } else {
          deleteds.push(change.doc.id);
        }
      });
      cb(data, deleteds);
    }
  );
}

export function argumentsListener(
  cb: (data: Argument[], deleteds: string[]) => void
) {
  return onSnapshot(
    collection(firestore, PATHS.arguments),
    async (querySnapshot) => {
      const data: Argument[] = [];
      const deleteds: string[] = [];
      querySnapshot.docChanges().forEach((change) => {
        if (change.type !== "removed") {
          const dta: unknown = {
            ...change.doc.data(),
            id: change.doc.id,
            created_at:
              change.doc.data().created_at?.toDate()?.getTime() ||
              new Date().getTime(),
          };
          data.push(dta as Argument);
        } else {
          deleteds.push(change.doc.id);
        }
      });
      cb(data, deleteds);
    }
  );
}

export function moodsListener(cb: (data: Mood[], deleteds: string[]) => void) {
  return onSnapshot(
    collection(firestore, PATHS.moods),
    async (querySnapshot) => {
      const data: Mood[] = [];
      const deleteds: string[] = [];
      querySnapshot.docChanges().forEach((change) => {
        if (change.type !== "removed") {
          const dta: unknown = {
            ...change.doc.data(),
            id: change.doc.id,
            created_at:
              change.doc.data().created_at?.toDate()?.getTime() ||
              new Date().getTime(),
          };

          data.push(dta as Mood);
        } else {
          deleteds.push(change.doc.id);
        }
      });
      cb(data, deleteds);
    }
  );
}

export function useResponseContact(cb: () => void) {
  const [loading, setLoading] = useState(false);

  const onResponse = useCallback(
    async (id: string, response: string) => {
      setLoading(true);
      try {
        await responseMessageContact(id, response);
        setTimeout(() => cb(), 400);
        setTimeout(() => {
          Swal.fire({
            icon: "success",
            title: "Réponse envoyé avec succés",
            showConfirmButton: false,
            timer: 5000,
            ...SWAL_DEFAULT_PROPS,
          });
          setLoading(false);
        }, 800);
      } catch (ex) {
        console.log("error", ex);
        setTimeout(() => cb(), 400);
        setTimeout(() => {
          Swal.fire({
            icon: "error",
            title: "Réponse non envoyé",
            showConfirmButton: false,
            timer: 5000,
            ...SWAL_DEFAULT_PROPS,
          });
          setLoading(false);
        }, 800);
      }
    },
    [cb]
  );

  return {
    loading,
    onResponse,
  };
}

export async function getUserByCode(code: string) {
  const data: UserInfos[] = [];
  const docs = await getDocs(
    query(collection(firestore, PATHS.users), where("code", "==", code))
  );
  docs.forEach((doc) => {
    const dta: unknown = {
      ...doc.data(),
      uid: doc.id,
      date: doc.data().date?.toDate()?.getTime() || new Date().getTime(),
    };
    data.push(dta as UserInfos);
  });
  return data[0];
}

export async function updateMoodAsync(uid: string, data: MoodFormData) {
  if (data.image instanceof File) {
    data.image = await uploadMoodImageAsync(data.image, uid);
  }
  return updateDoc(doc(firestore, PATHS.moods, uid), data);
}

export async function createMoodAsync(data: MoodFormData) {
  const docRef = collection(firestore, PATHS.moods);
  if (data.image instanceof File) {
    data.image = await uploadMoodImageAsync(data.image, docRef.id);
  }
  data.created_at = serverTimestamp();
  return addDoc(docRef, data);
}

export async function deleteMoodAsync(id: string) {
  const docRef = doc(firestore, PATHS.moods, id);
  return deleteDoc(docRef);
}

export async function updateSubjectAsync(uid: string, data: SubjectFormData) {
  return updateDoc(doc(firestore, PATHS.subjects, uid), data);
}

export async function createSubjectAsync(data: SubjectFormData) {
  const docRef = collection(firestore, PATHS.subjects);
  data.created_at = serverTimestamp();
  return addDoc(docRef, data);
}

export async function deleteSubjectAsync(id: string) {
  const docRef = doc(firestore, PATHS.subjects, id);
  return deleteDoc(docRef);
}

export async function updateTypeEtablissementAsync(
  uid: string,
  data: TypeEtablissementFormData
) {
  return updateDoc(doc(firestore, PATHS.type_etablissement, uid), data);
}

export async function createTypeEtablissementAsync(
  data: TypeEtablissementFormData
) {
  const docRef = collection(firestore, PATHS.type_etablissement);
  data.created_at = serverTimestamp();
  return addDoc(docRef, data);
}

export async function deleteTypeEtablissementAsync(id: string) {
  const docRef = doc(firestore, PATHS.type_etablissement, id);
  return deleteDoc(docRef);
}

export async function updateArgumentAsync(uid: string, data: ArgumentFormData) {
  return updateDoc(doc(firestore, PATHS.arguments, uid), data);
}

export async function createPrefAsync(data: TypeFormData) {
  const docRef = collection(firestore, PATHS.types);
  // data.created_at = serverTimestamp();
  return addDoc(docRef, data);
}

export async function deletePrefAsync(id: string) {
  const docRef = doc(firestore, PATHS.types, id);
  return deleteDoc(docRef);
}

export async function updatePrefAsync(uid: string, data: TypeFormData) {
  return updateDoc(doc(firestore, PATHS.types, uid), data);
}

export async function createArgumentAsync(data: ArgumentFormData) {
  const docRef = collection(firestore, PATHS.arguments);
  data.created_at = serverTimestamp();
  return addDoc(docRef, data);
}

export async function majAppConfig(data: AppConfigFormData) {
  const docRef = doc(firestore, "app", "app");
  return setDoc(docRef, data);
}

export async function deleteArgumentAsync(id: string) {
  const docRef = doc(firestore, PATHS.arguments, id);
  return deleteDoc(docRef);
}

export function visitesListener(
  bubbleZoneId: string,
  cb: (data: Visite[]) => void
) {
  const q = query(
    collection(firestore, PATHS.visites),
    where("bubbleZoneId", "==", bubbleZoneId)
  );
  return onSnapshot(q, async (querySnapshot) => {
    const data: Visite[] = [];
    querySnapshot.docChanges().forEach((change) => {
      if (change.type !== "removed") {
        const dta: unknown = {
          ...change.doc.data(),
          created_at:
            change.doc.data().created_at?.toDate()?.getTime() ||
            new Date().getTime(),
          id: change.doc.id,
        };
        data.push(dta as Visite);
      }
    });
    cb(data);
  });
}

export function createContactMessage(
  user: UserInfos,
  email: string,
  sujet: string,
  message: string
) {
  const sendData: MessageContactFormData = {
    email: email,
    pseudo: user.pseudo,
    sujet,
    message,
    created_at: serverTimestamp(),
    owner: true,
    responses: [],
  };
  const doc = collection(firestore, PATHS.contacts);
  return addDoc(doc, sendData);
}

const chance = new Chance();

export function MajDatabaseData() {
  getDocs(collection(firestore, PATHS.bubble_zone)).then((docs) => {
    docs.forEach((doc) => {
      updateBubbleZoneAsync(doc.id, { code: generateBubbleCode() });
    });
  });
  getDocs(
    query(
      collection(firestore, PATHS.users),
      where("is_proprietaire", "==", true)
    )
  ).then((docs) => {
    docs.forEach((doc) => {
      updateUserAsync(doc.id, { code: generateUserCode() });
    });
  });
}

export function generateBubbleCode() {
  return (
    "B+" +
    chance.string({ alpha: true, numeric: true, casing: "upper", length: 5 })
  );
}

export function generateUserCode() {
  return (
    "G+" +
    chance.string({ alpha: true, numeric: true, casing: "upper", length: 5 })
  );
}
