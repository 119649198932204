import React from "react";
import { FiChevronDown } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import Logo from "../../../../assets/appImages/logo.png";
import { useUser } from "../../../../redux/root";
import { logoutAsync } from "../../../../utils/api/auth.api";
import { getAvatar } from "../../../../utils/data";
import "./HeaderAdmin.css";
import { MdOutlineNotificationsActive } from "react-icons/md";
import SidebarMobile from "../SidebarAdmin/SidebarMobile";

const HeaderAdmin: React.FC = () => {
  const user = useUser();

  const [showNotifications, setShowNotifications] = React.useState(false);

  const onShowAndHideNotification = () => {
    if (showNotifications === false) {
      setShowNotifications(true);
    } else {
      setShowNotifications(false);
    }
  };

  return (
    <div>
      <div className="admin-header-component">
        <nav className="empty-fluidy alling-header navbar navbar-expand-md navbar-dark fixed-top">
          <div className="headering-admin row">
            <div className="col-md-4"></div>
            <div className="col-4">
              <div className="logo-item">
                <div className="logo-container dashboard-header-logo">
                  <span className="navbar-brand">
                    <img
                      src={Logo}
                      alt="logo bubblezone"
                      className="app-logo"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="header-others-items">
                <div className="search-form-container"></div>

                <div className="d-flex gap-5 avatar-and-notification-container">
                  <div
                    className="notification-item-container linkable position-relative"
                    onClick={() => onShowAndHideNotification()}
                  >
                    <div className="notification-item-content">
                      <MdOutlineNotificationsActive color="#0C0B0B" />
                      <span className="notification-counter">{1}</span>
                    </div>
                  </div>

                  <div className="connected-user-container">
                    <div className="connected-user-content ml-auto dis-flex gap-2 no-view-mobile">
                      {user?.is_proprietaire && (
                        <div className="header-pro-custom-pseudo-container">
                          <div className="header-pro-custom-pseudo">
                            {user.pseudo}
                          </div>
                          <div className="header-pro-custom-code">
                            {user.code}
                          </div>
                        </div>
                      )}
                      <div className="dropdown dis-flex">
                        <button
                          className="user-acount-dashboard-header dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <div className="user-acount-dashboard-header-display">
                            <img
                              src={getAvatar(user)}
                              width="45"
                              className="user-avatar"
                              alt="User Avatar"
                            />
                            <FiChevronDown />
                          </div>
                        </button>
                        <div
                          className="dropdown-menu dropdown-menu-custom"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <NavLink
                            to={
                              user?.is_proprietaire
                                ? "/proprietaire/profil"
                                : "/admin/profil"
                            }
                            className="dropdown-item dropdown-item-header-btn"
                          >
                            {!user?.is_proprietaire
                              ? user.pseudo
                              : "Mon profil"}
                          </NavLink>
                          <div className="dropdown-divider"></div>
                          <button
                            type="button"
                            onClick={async () => {
                              logoutAsync();
                            }}
                            className="dropdown-item dropdown-item-header-btn"
                          >
                            {" "}
                            Deconnexion
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="hiddenable-nav-mobile-container no-view-desktop">
            <div className="hiddenable-nav-mobile-content">
              <div className="hiddenable-nav-mobile-content">
                  <div className="container-nav-mobile">
                    <SidebarMobile />
                  </div>
                </div>
            </div>
          </div>

          {showNotifications && (
            <div className={"header-hiddenable-notification-section"}>
              {/* {data && data?.length > 0 ? (
							<ul className="header-hiddenable-notification-ul mb-0">
								{data
									?.slice(0, 4)
									?.map((item) => (
										<li
											className="header-hiddenable-notification-li"
											key={
												item?.slug
											}
										>
											<NavLink
												to={
													"/admin/notifications"
												}
												onClick={() =>
													readNotif(
														item?.slug
													)
												}
												className="notification-item-link d-flex no-link b-0"
											>
												<div className="content-text-notif ps-2">
													<p className="text-notif mb-1">
														{
															item?.content
														}
													</p>
													<p className="text-date-notif mb-0">
														{moment(
															item?.created_at
														).format(
															"DD/MM/YYYY-HH:MM"
														)}
													</p>
												</div>
											</NavLink>
										</li>
									))}
								<li className="header-hiddenable-notification-li">
									<NavLink
										to="/admin/notifications"
										className="notification-item-link d-flex no-link"
										// onClick={readAll}
									>
										<div className="content-text-notif ps-2">
											<p className="text-notif m-0">
												Voir
												toutes
												les
												notifications
											</p>
										</div>
									</NavLink>
								</li>
							</ul>
						) : ( */}
              <ul className="header-hiddenable-notification-ul">
                <li className="header-hiddenable-notification-li">
                  Il n'y a aucune notification .
                </li>
              </ul>
              {/* )} */}
            </div>
          )}
        </nav>
      </div>

      {/* <div className="admin-header-component">
      <nav className="empty-fluidy alling-header navbar navbar-expand-md navbar-dark fixed-top">
        <div className="headering-admin">
          <div className="container-logo-app-header-admin">
            <img src={Logo} alt="Logo" className="img-logo-app-header-admin" />
          </div>

          <div className="header-others-items">
            <div className="container-info-user-connect-header">
              <div className="container-menu-header-admin gap-3">
                <div className="dropdown dis-flex">
                  <button
                    className="user-acount-dashboard-header dropdown-toggle btn"
                    type="button"
                    id="dropdownNotificationMenuButton"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="user-acount-dashboard-header-display g-2">
                      <div
                        style={{ position: "relative", overflow: "visible" }}
                      >
                        <span className="notif-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="26"
                            viewBox="0 0 25 26"
                            fill="none"
                          >
                            <path
                              d="M23.4315 11.6458C22.983 11.6458 22.619 11.2818 22.619 10.8333C22.619 7.72195 21.408 4.79807 19.2087 2.59781C18.8914 2.28043 18.8914 1.76587 19.2087 1.44849C19.5261 1.1311 20.0407 1.1311 20.3583 1.44849C22.864 3.95522 24.244 7.28873 24.244 10.8333C24.244 11.2818 23.88 11.6458 23.4315 11.6458Z"
                              fill="#0C0B0B"
                            />
                            <path
                              d="M1.22266 11.6458C0.774155 11.6458 0.410156 11.2818 0.410156 10.8333C0.410156 7.28871 1.79037 3.9552 4.2971 1.44946C4.61449 1.13208 5.12924 1.13208 5.44662 1.44946C5.76401 1.76685 5.76401 2.2816 5.44662 2.59898C3.24637 4.79805 2.03516 7.72194 2.03516 10.8333C2.03516 11.2818 1.67116 11.6458 1.22266 11.6458Z"
                              fill="#0C0B0B"
                            />
                            <path
                              d="M12.3262 26C10.0858 26 8.26367 24.1778 8.26367 21.9375C8.26367 21.489 8.62767 21.125 9.07617 21.125C9.52467 21.125 9.88867 21.489 9.88867 21.9375C9.88867 23.282 10.9817 24.375 12.3262 24.375C13.6705 24.375 14.7637 23.282 14.7637 21.9375C14.7637 21.489 15.1277 21.125 15.5762 21.125C16.0247 21.125 16.3887 21.489 16.3887 21.9375C16.3887 24.1778 14.5665 26 12.3262 26Z"
                              fill="#0C0B0B"
                            />
                            <path
                              d="M21.2651 22.75H3.39011C2.34453 22.75 1.49414 21.8996 1.49414 20.8542C1.49414 20.2994 1.73575 19.7741 2.15727 19.4133C3.80489 18.0212 4.74414 15.9977 4.74414 13.8538V10.8333C4.74414 6.65175 8.14589 3.25 12.3276 3.25C16.5091 3.25 19.9109 6.65175 19.9109 10.8333V13.8538C19.9109 15.9977 20.8501 18.0212 22.487 19.4058C22.9193 19.7741 23.1609 20.2994 23.1609 20.8542C23.1609 21.8996 22.3105 22.75 21.2651 22.75ZM12.3276 4.875C9.0417 4.875 6.36914 7.54756 6.36914 10.8333V13.8538C6.36914 16.4763 5.21982 18.9529 3.21674 20.6461C3.17885 20.6787 3.11914 20.7459 3.11914 20.8542C3.11914 21.0014 3.24272 21.125 3.39011 21.125H21.2651C21.4123 21.125 21.5359 21.0014 21.5359 20.8542C21.5359 20.7459 21.4764 20.6787 21.4405 20.6483C19.4352 18.9529 18.2859 16.4763 18.2859 13.8538V10.8333C18.2859 7.54756 15.6133 4.875 12.3276 4.875Z"
                              fill="#0C0B0B"
                            />
                            <path
                              d="M12.3262 4.875C11.8777 4.875 11.5137 4.511 11.5137 4.0625V0.8125C11.5137 0.363998 11.8777 0 12.3262 0C12.7747 0 13.1387 0.363998 13.1387 0.8125V4.0625C13.1387 4.511 12.7747 4.875 12.3262 4.875Z"
                              fill="#0C0B0B"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-custom dropdown-menu-notifications shadow-lg"
                    aria-labelledby="dropdownNotificationMenuButton"
                  >
                    <div className="dropdown-item">
                      <div
                        style={{
                          fontSize: 13,
                          fontWeight: 500,
                          color: "inherit",
                          whiteSpace: "normal",
                        }}
                      >
                        Aucune notification disponible
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="connected-user-container" id="header-user-admin">
                <div className="connected-user-content ml-auto">
                  <div className="dropdown dis-flex">
                    <button
                      className="user-acount-dashboard-header dropdown-toggle btn"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div className="user-acount-dashboard-header-display g-2">
                        <img
                          src={"Avatar"}
                          className="user-avatar"
                          alt="User Avatar"
                        />
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill="none"
                          >
                            <path
                              d="M4.78321 4.81754L7.76026 1.8405C7.94189 1.65886 7.94189 1.36428 7.76026 1.18264C7.57858 1.00097 7.28408 1.00097 7.1024 1.18264L4.91945 3.3656C4.91945 3.3656 4.69978 3.61161 4.45605 3.60645C4.21819 3.60141 3.98912 3.3656 3.98912 3.3656L1.80616 1.18272C1.62449 1.00104 1.32998 1.00104 1.14831 1.18272C1.05754 1.27352 1.01207 1.3926 1.01207 1.51164C1.01207 1.63069 1.05754 1.74973 1.14831 1.84057L4.12535 4.81754C4.30703 4.99922 4.60153 4.99922 4.78321 4.81754Z"
                              fill="#0C0B0B"
                              stroke="#0C0B0B"
                              stroke-width="0.5"
                            />
                          </svg>
                        </span>
                      </div>
                    </button>
                    <div
                      className="dropdown-menu dropdown-menu-custom"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <NavLink
                        to="/admin/profil"
                        className="dropdown-item"
                        style={{ fontSize: 16 }}
                      >
                        <span className="pe-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                          >
                            <path
                              d="M6.0793 5.435C6.0293 5.43 5.9693 5.43 5.9143 5.435C4.7243 5.395 3.7793 4.42 3.7793 3.22C3.7793 1.995 4.7693 1 5.9993 1C7.2243 1 8.2193 1.995 8.2193 3.22C8.2143 4.42 7.2693 5.395 6.0793 5.435Z"
                              stroke="black"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M3.57938 7.28C2.36937 8.09 2.36937 9.41 3.57938 10.215C4.95438 11.135 7.20938 11.135 8.58438 10.215C9.79438 9.405 9.79438 8.085 8.58438 7.28C7.21438 6.365 4.95938 6.365 3.57938 7.28Z"
                              stroke="black"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                        <span>Profil</span>
                      </NavLink>
                      <div className="dropdown-divider"></div>
                      <button
                        type="button"
                        className="dropdown-item"
                        style={{ fontSize: 16 }}
                      >
                        <span className="pe-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="14"
                            viewBox="0 0 15 14"
                            fill="none"
                          >
                            <path
                              d="M11.5 10L14.5 7M14.5 7L11.5 4M14.5 7H4M8.5 10V10.75C8.5 11.3467 8.26295 11.919 7.84099 12.341C7.41903 12.7629 6.84674 13 6.25 13H3.25C2.65326 13 2.08097 12.7629 1.65901 12.341C1.23705 11.919 1 11.3467 1 10.75V3.25C1 2.65326 1.23705 2.08097 1.65901 1.65901C2.08097 1.23705 2.65326 1 3.25 1H6.25C6.84674 1 7.41903 1.23705 7.84099 1.65901C8.26295 2.08097 8.5 2.65326 8.5 3.25V4"
                              stroke="black"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                        <span>Se déconnecter</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="hiddenable-nav-mobile-container no-view-desktop">
                <div className="hiddenable-nav-mobile-content">
                  <div className="container-nav-mobile">
                    <SidebarMobile />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div> */}
    </div>
  );
};

export default HeaderAdmin;
