import { getApps, initializeApp } from "firebase/app";
// Optionally import the services that you want to use

const firebaseConfig = {
  apiKey: "AIzaSyAXJOqBrRwgGOosZ2LM0Zrfn6dz471rKKk",
  authDomain: "bubblezone-c8cf5.firebaseapp.com",
  projectId: "bubblezone-c8cf5",
  storageBucket: "bubblezone-c8cf5.appspot.com",
  messagingSenderId: "360667003447",
  appId: "1:360667003447:web:3185b260801d751573ddb2",
  measurementId: "G-QK9R937N47",
};

export const PATHS = {
  users: "users",
  bubble_zone: "bubble_zone",
  annonces: "annonces",
  contacts: "contacts",
  contents: "contents",
  intobulle: "intobulle_history",
  eyes_contacts: "eyes_contacts",
  chats: "chats",
  offres: "offres",
  note_avis: "note_avis",
  subjects: "subjects",
  type_etablissement: "type_etablissement",
  moods: "moods",
  arguments: "arguments",
  visites: "visites",
  types: "types",
};

export const STORAGE_PATHS = {
  avatars: "avatars",
  bubble_zone: "bubble_zone",
  moods: "moods",
};

if (getApps().length === 0) {
  initializeApp(firebaseConfig);
}
