import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { useState } from "react";
import { Bar } from "react-chartjs-2";
import UpgradeVersionModal from "../../../../modal/proprietaire/UpgradeVersionModal";
ChartJS.register(Tooltip, Legend);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
};

const ConnexionChartProprietaire = ({
  data,
  disabled,
}: {
  data: { hours: string; count: number }[];
  disabled?: boolean;
}) => {
  const config = {
    labels: data.map((c) => `${c.hours}h`),
    datasets: [
      {
        label: "",
        data: data.map((c) => c.count),
        backgroundColor: "#DB3251",
      },
    ],
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
   const handleMouseEnter = () => {
    if (disabled) {
      setShow(true);
    }
  };
  return (
    <>
      <div 
        className={disabled ? "blured" : ""} 
        style={{ height: "auto" }}
        onMouseEnter={handleMouseEnter}
      >
        <Bar
          options={{ ...options, ...(disabled ? { events: [] } : {}) }}
          data={config}
        />
      </div>

      {/* Modal */}
      <UpgradeVersionModal show={show} handleClose={handleClose} />
    </>
  );
};

export default ConnexionChartProprietaire;
