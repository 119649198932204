import React from "react";
import Swal from "sweetalert2";
import { MessageContactFormData } from "../../../../../types";
import { useUser } from "../../../../redux/root";
import { createContactMessage } from "../../../../utils/api/user.api";
import { SWAL_DEFAULT_PROPS } from "../../../../utils/data";
import { useFormData } from "../../../../utils/form";
import { FormError } from "../../../auth/Login";
import { BtnSubmit } from "../../../common/Button";
import { Input } from "../../../common/Input";
import "./AssistanceOwner.css";

function AssistanceOwner() {
  const user = useUser();
  const { errors, setErrors, onChange, onSubmitForm, data, register } =
    useFormData<MessageContactFormData>(
      {
        message: "",
        sujet: "",
      },
      (Yup) => ({
        message: Yup.string().required().label("Message"),
        sujet: Yup.string().required().label("Objet"),
      })
    );
  const [loading, setLoading] = React.useState(false);
  const onSubmit = React.useCallback(
    async (data: MessageContactFormData) => {
      setLoading(true);
      try {
        await createContactMessage(
          user,
          user.email,
          data.sujet as string,
          data.message as string
        );
        Swal.fire({
          title: "Succès",
          text: `Message envoyé avec succès`,
          icon: "success",
          ...SWAL_DEFAULT_PROPS,
        });
        onChange({
          message: "",
          sujet: "",
        });
      } catch (ex) {
        setErrors({ sujet: "Une erreur est survenue lors de l'envoie" });
        console.log("add error", ex);
      }
      setLoading(false);
    },
    [user]
  );
  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page bg-white p-3">
        <div className="admin-page-title-header-container">
          <div className="bubble-zone-dash-locataire-requetes-header">
            <h4 className="bubble-zone-dash-header-titre">Assistance</h4>
          </div>
        </div>
        <div className="col-left-dashboard col-left-dashboard-responsive">
          <div className="">
            <div className="flex-sb align-items-center mb-4">
              <div className="tab-dash-admin-filter-container w-100">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    onSubmitForm(onSubmit);
                  }}
                  className="row g-3 tab-dash-admin-filter-form"
                >
                  <div className="col-md-12">
                    <Input
                      type="text"
                      label="Objet"
                      placeholder="Objet de votre message"
                      customClassLabel={"input-modal-label-form-add"}
                      customClassInput="input-modal-form-add"
                      {...register("sujet")}
                    />
                    <FormError error={errors.sujet} />
                  </div>
                  <div className="col-md-12">
                    <label
                      htmlFor={""}
                      className={`form-label input-modal-label-form-add `}
                    >
                      Message
                    </label>
                    <textarea
                      className="input-modal-form-add w-100"
                      rows={5}
                      placeholder="Écrivez votre message"
                      value={data.message}
                      onChange={(e) => {
                        onChange("message", e.target.value);
                      }}
                    ></textarea>
                    <FormError error={errors.message} />
                  </div>
                  <div className="col-md-12">
                    <div className="flex-r pt-5">
                      <BtnSubmit label="Envoyer" isLoading={loading} />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssistanceOwner;
