import React from "react";
import Swal from "sweetalert2";
import { Offer } from "../../../../../../types";
import { updateOffer } from "../../../../../utils/api/user.api";
import { SWAL_DEFAULT_PROPS } from "../../../../../utils/data";
import { useFormData } from "../../../../../utils/form";
import { SubmitButton } from "../../../../auth/Login";
import { FormError, Input, InputWithAddon } from "../../../../common/Input";
import CloseModalBtn from "../../../../shared/CloseModalBtn";

function AddOffreModal({ item, onHide }: { item: Offer; onHide: () => void }) {
  const { id, ...formData } = item;

  const { errors, setErrors, onChange, onSubmitForm, register, data } =
    useFormData<Offer>(formData, (Yup) => ({
      nom: Yup.string().required().label("Nom"),
      price: Yup.string().required().label("Prix"),
      description: Yup.string().required().label("Description"),
    }));
  const [loading, setLoading] = React.useState(false);
  const onSubmit = React.useCallback(
    async (data: Offer) => {
      setLoading(true);
      try {
        await updateOffer(item.id ?? "", data);
        onHide();
        setTimeout(() => {
          Swal.fire({
            title: "Succès",
            text: "Informations modifiées avec succès",
            icon: "success",
            ...SWAL_DEFAULT_PROPS,
          });
        }, 400);
      } catch (ex) {
        setErrors({ nom: "Une erreur est survenue lors de l'ajout" });
        console.log("add error", ex);
      }
      setLoading(false);
    },
    [onHide]
  );
  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header py-2 flex-m">
          <h5 className="admin-modal-title text-start">Modifier une offre</h5>
          <CloseModalBtn />
        </div>
        <div className="modal-body">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmitForm(onSubmit);
            }}
          >
            <div className="container-form">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <Input
                    type="text"
                    label="Nom de l'offre"
                    placeholder="Ajouter un nom"
                    {...register("nom")}
                    error={errors.nom}
                    customClassLabel={"input-modal-label-form-add"}
                    customClassInput="input-modal-form-add"
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <InputWithAddon
                    type="text"
                    label="Prix"
                    placeholder="Ajouter un prix"
                    customClassLabel={"input-modal-label-form-add"}
                    customClassInput="input-modal-form-add border-end-0"
                    addon="Euro"
                    addonPosition="end"
                    {...register("price")}
                    error={errors.price}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <div className="mb-3">
                    <label
                      htmlFor="description"
                      className="form-label input-modal-label-form-add"
                    >
                      Description
                    </label>
                    <textarea
                      className="form-control"
                      id="description"
                      value={data.description}
                      name="description"
                      onChange={(e) => {
                        onChange("description", e.target.value);
                      }}
                      rows={5}
                    ></textarea>
                  </div>
                  {!!errors?.description && (
                    <FormError error={errors?.description} />
                  )}
                </div>
              </div>
              <div className="gap-3 flex-r align-center row my-4">
                <div className="col-md-3">
                  <SubmitButton title="Ajouter" loading={loading} />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddOffreModal;
