import GoogleMapReact from "google-map-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BubbleZone } from "../../../../../types";
function getLatLong(item: BubbleZone) {
  const lat = parseFloat(item?.latitude);
  const lng = parseFloat(item?.longitude);
  return {
    lat: isNaN(lat) ? 0 : lat,
    lng: isNaN(lng) ? 0 : lng,
  };
}

const AnyReactComponent = ({ text, onClick, lat, lng }) => {
  return (
    <img
      src={require("../../../../assets/appImages/pin.png")}
      className="map"
      onClick={onClick}
    />
  );
};

function BubbleMap({ data }: { data: BubbleZone[] }) {
  const navigate = useNavigate();
  const [changed, setChanged] = useState(true);

  const defaultProps = {
    center: getLatLong(data[0]),
    zoom: 11,
  };

  useEffect(() => {
    setChanged(true);
    setTimeout(() => setChanged(false), 400);
  }, [data]);

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "60vh", width: "100%" }}>
      {!changed && (
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDO5Ffu3pFMv-aqH3En3q_SF5bv_O35pWg" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          {data.map((c) => {
            const { lat, lng } = getLatLong(c);
            return (
              <AnyReactComponent
                onClick={() => {
                  navigate(`/proprietaire/donnees-client/${c.id}`, {
                    state: c,
                  });
                }}
                key={c.id}
                lat={lat}
                lng={lng}
                text={c.name}
              />
            );
          })}
        </GoogleMapReact>
      )}
    </div>
  );
}

export default BubbleMap;
