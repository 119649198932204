import React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { UserFormData } from "../../../types";
import Logo from "../../assets/appImages/logo.png";
import {
  createProprietaireFunction,
  generateUserCode,
} from "../../utils/api/user.api";
import { SWAL_DEFAULT_PROPS } from "../../utils/data";
import { useFormData } from "../../utils/form";
import GooglePlaceInput from "../common/GooglePlaceInput";
import { Input } from "../common/Input";
import { FormError, SubmitButton } from "./Login";

function Register() {
  const navigate = useNavigate();
  const { data, errors, register, onChange, onSubmitForm, setErrors } =
    useFormData<UserFormData>(
      {
        pseudo: "",
        email: "",
        numero_siret: "",
        raison_social: "",
        address: "",
        phone: "",
        age: "0",
        is_admin: false,
        is_super_admin: false,
        is_proprietaire: true,
        jetons: 0,
        orientation_sexuelle: "Hétérosexuel",
        mood: "Date",
        code: generateUserCode(),
        subjects: [],
        sexe: "Homme",
      },
      (Yup) => ({
        pseudo: Yup.string().required().label("Nom du reponsable"),
        email: Yup.string().email().required().label("Adresse mail"),
        raison_social: Yup.string().required().label("Raison sociale"),
        numero_siret: Yup.string().required().label("Numéro de siret"),
        address: Yup.string().required().label("Adresse postale"),
        phone: Yup.string().required().label("Numéro de téléphone"),
      })
    );

  const [loading, setLoading] = React.useState(false);
  const onSubmit = React.useCallback(async (data: UserFormData) => {
    setLoading(true);
    try {
      type Res = { data: { ok: boolean } } | null;
      const res = (await createProprietaireFunction(data)) as Res;
      if (res?.data?.ok) {
        setTimeout(() => {
          Swal.fire({
            title: "Inscription terminé avec succès",
            text: `Un e-mail contenant vos identifiants de connexion vous a été envoyé. Veuillez vérifier votre boîte de réception`,
            icon: "success",
            ...SWAL_DEFAULT_PROPS,
          });
          navigate("/");
        }, 400);
      } else {
        if (res?.data?.ok === false) {
          setErrors({ email: "Adresse E-Mail déjà utilisée" });
        } else {
          setErrors({ email: "Une erreur est survenue lors de l'ajout" });
        }
      }
    } catch (ex) {
      setErrors({ email: "Une erreur est survenue lors de l'ajout" });
      console.log("add error", ex);
    }
    setLoading(false);
  }, []);
  return (
    <div className="row">
      <div className="col-md-12 right-side-col">
        <div className="login-form-container custom-register-form-container">
          <div className="login-form-introduction d-flex flex-column align-items-center mb-5">
            <div className="mb-4">
              <img src={Logo} alt="logo" className="logo-auth-login-img" />
            </div>
            <h1 className="auth-form-title">Créer un profil</h1>
            <div className="auth-form-title-subtitle">
              Saisissez vos informations personnels afin de remplir votre
              profil. Il ne s’agit pas du profil de votre établissement sur
              l’application mobile mais celui de votre entreprise. Les
              informations saisies sont modifiables par la suite
            </div>
          </div>
          <div className="mt-4">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmitForm(onSubmit);
              }}
              id="auth-form"
            >
              <div className="row">
                <div className="col-md-4">
                  <label
                    htmlFor="pseudo"
                    className="bubblezone-ajout-bubble-form-label"
                  >
                    Nom du reponsable
                  </label>
                </div>
                <div className="col-md-8 mb-3">
                  <Input
                    {...register("pseudo")}
                    type="text"
                    placeholder="Dupond Charles"
                  />
                </div>

                <div className="col-md-4">
                  <label
                    htmlFor="raison_social"
                    className="bubblezone-ajout-bubble-form-label"
                  >
                    Raison sociale
                  </label>
                </div>
                <div className="col-md-8 mb-3">
                  <Input
                    type="text"
                    placeholder="SAS le bar du coin "
                    {...register("raison_social")}
                  />
                </div>
                <div className="col-md-4">
                  <label
                    htmlFor="numero_siret"
                    className="bubblezone-ajout-bubble-form-label"
                  >
                    Numéro de siret
                  </label>
                </div>
                <div className="col-md-8 mb-3">
                  <Input
                    type="text"
                    {...register("numero_siret")}
                    placeholder="000000000"
                  />
                </div>
                <div className="col-md-4">
                  <div className="bubblezone-ajout-bubble-form-label">
                    Adresse postale
                  </div>
                </div>
                <div className="col-md-8 mb-3">
                  <GooglePlaceInput
                    onChange={(data) => {
                      onChange({
                        address: data.rue,
                      });
                    }}
                    value={data.address as string}
                    className="form-control form-control-modal-custom"
                    placeholder="D2 rue des bistrots, 33000 Bordeaux"
                  />
                  <FormError error={errors.address} />
                </div>
                <div className="col-md-4">
                  <label
                    htmlFor="phone"
                    className="bubblezone-ajout-bubble-form-label"
                  >
                    Numéro de téléphone
                  </label>
                </div>
                <div className="col-md-8 mb-3">
                  <Input
                    type="tel"
                    {...register("phone")}
                    placeholder="06 66 66 66 66"
                  />
                </div>
                <div className="col-md-4">
                  <label
                    htmlFor="email"
                    className="bubblezone-ajout-bubble-form-label"
                  >
                    Adresse mail
                  </label>
                </div>

                <div className="col-md-8 mb-3">
                  <Input
                    type="email"
                    {...register("email")}
                    placeholder="exemple@exemple.fr"
                  />
                </div>
                <div className="col-md-4"></div>
                <div className="col-md-8 offset-md-4 m-auto auth-submit-btn-container mt-5 mb-2">
                  <SubmitButton title="Créer mon profil" loading={loading} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
