import moment from "moment";
import React from "react";
import { BsTrashFill } from "react-icons/bs";
import Swal from "sweetalert2";
import { Annonce as An, AnnonceFormData } from "../../../../../types";
import { useAppSelector } from "../../../../redux/root";
import {
  createAnnonceAsync,
  deleteAnnonceAsync,
} from "../../../../utils/api/user.api";
import { SWAL_DEFAULT_PROPS } from "../../../../utils/data";
import { useFormData } from "../../../../utils/form";
import { FormError } from "../../../auth/Login";
import { BtnSubmit } from "../../../common/Button";
import { Input } from "../../../common/Input";
import "./Annonce.css";

function Annonce() {
  const annonces = useAppSelector((s) => s.annonces);
  const { errors, setErrors, onChange, onSubmitForm, register, data } =
    useFormData<AnnonceFormData>(
      {
        content: "",
        date: "",
        time: "",
      },
      (Yup) => ({
        content: Yup.string().required().label("Message"),
        date: Yup.string().required().label("Date"),
        time: Yup.string().required().label("Heure"),
      })
    );
  const [loading, setLoading] = React.useState(false);
  const onSubmit = React.useCallback(async (data: AnnonceFormData) => {
    setLoading(true);
    try {
      await createAnnonceAsync(data);

      setTimeout(() => {
        Swal.fire({
          title: "Succès",
          text: `Annonce ajoutée avec succès`,
          icon: "success",
          ...SWAL_DEFAULT_PROPS,
        });
        onChange({
          content: "",
          date: "",
          time: "",
        });
      }, 400);
    } catch (ex) {
      setErrors({ content: "Une erreur est survenue lors de l'ajout" });
      console.log("add error", ex);
    }
    setLoading(false);
  }, []);
  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page bg-white p-3">
        <div className="admin-page-title-header-container">
          <div className="bubble-zone-dash-locataire-requetes-header">
            <h4 className="bubble-zone-dash-header-titre">Annonces</h4>
          </div>
        </div>
        <div className="col-left-dashboard col-left-dashboard-responsive">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmitForm(onSubmit);
            }}
            className="row g-3 tab-dash-admin-filter-form"
          >
            <div className="bubblezone-annonce-label-container">
              <div className="bubblezone-annonce-label-date-heure">
                Date et heure de publication{" "}
              </div>
              <div className="bubblezone-annonce-input-date-container">
                <Input
                  type="date"
                  placeholder="Date "
                  customClassLabel={"input-modal-label-form-add"}
                  min={new Date().getTime()}
                  customClassInput="custom-input-modal-form-add"
                  {...register("date")}
                  error={errors.date}
                />
                <Input
                  type="time"
                  placeholder="Heure"
                  customClassLabel={"input-modal-label-form-add"}
                  customClassInput="custom-input-modal-form-add"
                  {...register("time")}
                  error={errors.time}
                />
              </div>
            </div>
            <div className="col-md-12">
              <textarea
                className="input-modal-form-add-custom w-100"
                rows={5}
                placeholder="Message"
                value={data.content}
                onChange={(e) => {
                  onChange("content", e.target.value);
                }}
              ></textarea>
              <FormError error={errors.content} />
            </div>
            <div className="col-md-12">
              <div className="flex-r pt-1">
                <BtnSubmit label="Publier" isLoading={loading} />
              </div>
            </div>
          </form>
          {annonces.map((item) => (
            <AnnonceItem item={item} key={item.id} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Annonce;

function AnnonceItem({ item }: { item: An }) {
  const onDelete = async () => {
    Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cette annonce ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        await deleteAnnonceAsync(item.id);
        Swal.fire({
          icon: "success",
          title: "Annonce supprimé avec succés",
          showConfirmButton: false,
          timer: 5000,
          ...SWAL_DEFAULT_PROPS,
        });
      },
      ...SWAL_DEFAULT_PROPS,
    });
  };

  return (
    <div
      className="py-1 px-2 mt-3"
      style={{ backgroundColor: "rgba(246, 246, 246, 0.56)", borderRadius: 10 }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <p>{item.content}</p>
          <p>
            {moment(item.date).format("DD/MM/YYYY")} {item.time}
          </p>
        </div>
        <div>
          <button
            className="btn with-tooltip btn-action-icon-delete"
            data-tooltip-content="Supprimer"
            onClick={() => onDelete()}
            style={{ color: "#E53E3E" }}
          >
            <BsTrashFill />
          </button>
        </div>
      </div>
    </div>
  );
}
