import React from 'react'
import "./MessageEmptyBubblezone.css"
import EmptyBz from "../../../../assets/appImages/empty-bz.svg"
import { NavLink } from 'react-router-dom'
import { IoMdAdd } from 'react-icons/io'

function MessageEmptyBubblezone() {
  return (
    <div className='empty-bubble-zone-item bg-white'>
      <div className='empty-bubble-zone-img-container mb-4'>
        <img src={EmptyBz} alt="empty bubble zone" className='empty-bubble-zone-img' />
      </div>
      <div className='empty-bubble-zone-mes-empty mb-0'>Aucune bubble zone</div>
      <div className='empty-bubble-zone-cta mb-4'>Merci d’ajouter une bubble zone</div>
      <div className="content-btn-add-theme">
        <NavLink
          to="/proprietaire/ajouter-bubblezone"
          className="btn custom-btn-add-theme"
        >
          <IoMdAdd />
          <span className="ps-2">Ajouter une bubblezone</span>
        </NavLink>
      </div>
    </div>
  )
}

export default MessageEmptyBubblezone