// import { SettingsApp } from "./Hobbies";
import "./ParametreAdmin.css";
import CentreInteretTab from "./ParametreAdminTabs/CentreInteretTab";
import MoodTab from "./ParametreAdminTabs/MoodTab";
// import { IoMdAdd } from "react-icons/io";
import { BsPlusSquare } from "react-icons/bs";
import Offretab from "./ParametreAdminTabs/Offretab";
// import CGUTab from "./ParametreAdminTabs/CGUTab";
// import PolitiqueTab from "./ParametreAdminTabs/PolitiqueTab";
import { useModalActions } from "../../../../utils/data";
import AddAdminModal from "../../../modal/admin/AddAdminModal";
import { Preferences } from "./Hobbies";
import AdministrateurTab from "./ParametreAdminTabs/AdministrateurTab";
import TermesConditions, {
  PolitiqueDeConfidentialite,
} from "./TermesConditions";

function ParametreAdminV2() {
  const ma = useModalActions("AddAdminModal");
  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page bg-white p-3 mb-3">
        <div className="admin-page-title-header-container mb-4">
          <div className="bubble-zone-dash-locataire-requetes-header">
            <h4 className="bubble-zone-dash-header-titre">Paramètres</h4>
          </div>
        </div>
        <div className="admin-page-title-header-container">
          <ul
            className="nav nav-pills custom-tab-dash-admin-container gap-1"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item nav-item-setting" role="presentation">
              <button
                className="nav-link custom-tab-dash-admin active"
                id="arguments-tab"
                data-bs-toggle="tab"
                data-bs-target="#arguments"
                type="button"
                role="tab"
                aria-controls="arguments"
                aria-selected="false"
              >
                Passions
              </button>
            </li>
            <li className="nav-item nav-item-setting" role="presentation">
              <button
                className="nav-link custom-tab-dash-admin"
                id="pref-tab"
                data-bs-toggle="tab"
                data-bs-target="#pref"
                type="button"
                role="tab"
                aria-controls="pref"
                aria-selected="false"
              >
                Préférences
              </button>
            </li>
            <li className="nav-item nav-item-setting" role="presentation">
              <button
                className="nav-link custom-tab-dash-admin"
                id="mood-tab"
                data-bs-toggle="tab"
                data-bs-target="#mood"
                type="button"
                role="tab"
                aria-controls="mood"
                aria-selected="false"
              >
                Mood
                {/* Caractéristiques */}
              </button>
            </li>
            <li className="nav-item nav-item-setting" role="presentation">
              <button
                className="nav-link custom-tab-dash-admin"
                id="offre-tab"
                data-bs-toggle="tab"
                data-bs-target="#offre"
                type="button"
                role="tab"
                aria-controls="offre"
                aria-selected="false"
              >
                Offres
              </button>
            </li>
            <li className="nav-item nav-item-setting" role="presentation">
              <button
                className="nav-link custom-tab-dash-admin"
                id="termes-conditions-tab"
                data-bs-toggle="tab"
                data-bs-target="#termes-conditions"
                type="button"
                role="tab"
                aria-controls="termes-conditions"
                aria-selected="false"
              >
                CGU
              </button>
            </li>
            <li className="nav-item nav-item-setting" role="presentation">
              <button
                className="nav-link custom-tab-dash-admin"
                id="ptc-tab"
                data-bs-toggle="tab"
                data-bs-target="#ptc"
                type="button"
                role="tab"
                aria-controls="termes-conditions"
                aria-selected="false"
              >
                Politique de confidentialité
              </button>
            </li>
            <li className="nav-item nav-item-setting" role="presentation">
              <button
                className="nav-link custom-tab-dash-admin"
                id="admins-tab"
                data-bs-toggle="tab"
                data-bs-target="#admins"
                type="button"
                role="tab"
                aria-controls="admins"
                aria-selected="true"
              >
                Administrateurs
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="container-dashboard-page mb-3">
        <div className="col-left-dashboard col-left-dashboard-responsive">
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active bg-white p-3"
              id="arguments"
              role="tabpanel"
              aria-labelledby="arguments-tab"
            >
              <CentreInteretTab />
            </div>

            <div
              className="tab-pane fade  bg-white p-3"
              id="mood"
              role="tabpanel"
              aria-labelledby="mood-tab"
            >
              <div>
                <div className="content-btn-add-theme flex-r mb-4">
                  <button
                    className="btn btn-add-theme btn-add-theme-custom"
                    data-bs-toggle="modal"
                    data-bs-target="#AddMoodModal"
                  >
                    <BsPlusSquare className="btn-add-theme-custom-icon" />
                    <span className="ps-2">Ajouter un mood</span>
                  </button>
                </div>
                <MoodTab />
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pref"
              role="tabpanel"
              aria-labelledby="pref-tab"
            >
              <Preferences />
            </div>
            <div
              className="tab-pane fade bg-white p-3"
              id="offre"
              role="tabpanel"
              aria-labelledby="offre-tab"
            >
              <div>
                <Offretab />
              </div>
            </div>

            <div
              className="tab-pane fade  bg-white p-3"
              id="termes-conditions"
              role="tabpanel"
              aria-labelledby="termes-conditions-tab"
            >
              {/* <CGUTab /> */}
              <TermesConditions />
            </div>
            <div
              className="tab-pane fade  bg-white p-3"
              id="ptc"
              role="tabpanel"
              aria-labelledby="ptc-tab"
            >
              {/* <PolitiqueTab /> */}
              <PolitiqueDeConfidentialite />
            </div>
            <div
              className="tab-pane fade bg-white p-3"
              id="admins"
              role="tabpanel"
              aria-labelledby="admins-tab"
            >
              <div>
                <div className="content-btn-add-theme flex-r mb-4">
                  <button
                    className="btn btn-add-theme btn-add-theme-custom"
                    data-bs-toggle="modal"
                    data-bs-target="#AddAdminModal"
                  >
                    <BsPlusSquare className="btn-add-theme-custom-icon" />
                    <span className="ps-2">Ajouter un administrateur</span>
                  </button>
                </div>
                <AdministrateurTab modalId="AddAdminModal" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="AddAdminModal"
        aria-labelledby="AddAdminModalLabel"
        aria-hidden="true"
      >
        <AddAdminModal
          onHide={() => {
            ma.hide();
          }}
        />
      </div>
    </div>
  );
}

export default ParametreAdminV2;
