import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { BsTrashFill } from "react-icons/bs";
import Swal from "sweetalert2";
import { UserInfos } from "../../../../../types";
import { useAppSelector } from "../../../../redux/root";
import { deleteNormalUserFunction } from "../../../../utils/api/user.api";
import {
  DefaultPaginationOptions,
  SWAL_DEFAULT_PROPS,
  getAvatar,
} from "../../../../utils/data";
import { ToggleUserActive } from "../BubbleZoneTable/BubbleZoneTable";
import { ShowButton } from "../ListeParticipantTable/ListeParticipantTable";
import ListeUsersTableSkeleton from "./ListeUsersTableSkeleton";
import "./Table.css";

function ListeUsersTable({ user = true }: { user?: boolean }) {
  const onDelete = async (id: string) => {
    Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cet utilisateur ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      ...SWAL_DEFAULT_PROPS,
      preConfirm: async () => {
        await deleteNormalUserFunction(id);
        Swal.fire({
          icon: "success",
          title: "utilisateur supprimé avec succés",
          showConfirmButton: false,
          timer: 5000,
          ...SWAL_DEFAULT_PROPS,
        });
      },
    });
  };
  const [page, setPage] = useState<UserInfos[]>([]);
  const users = useAppSelector((s) => s.users);
  const [isLoading, setIsLoading] = React.useState(true);
  useEffect(() => {
    if (user) {
      setPage(
        users.filter(
          (f) => !(f.is_admin || f.is_super_admin || f.is_proprietaire)
        )
      );
    } else {
      setPage(users.filter((f) => f.is_proprietaire));
    }
    setTimeout(() => setIsLoading(false), 600);
  }, [users]);
  const pseudoFormatter = (cell: string, row: UserInfos) => {
    return (
      <div className="table-actions-btn-container-img gap-2 d-flex align-items-center">
        <img src={getAvatar(row)} alt="avatar" className="profil-user-table" />{" "}
        <span className="ps-2">{row.pseudo}</span>
      </div>
    );
  };
  const actionFormatter = (cell: string, row: UserInfos) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          {/* @ts-ignore */}
          <ShowButton item={{ user: row }} />
          <div className="container-btn-action-icon-modal">
            <button
              className="btn with-tooltip btn-action-icon-delete"
              data-tooltip-content="Supprimer"
              onClick={() => onDelete(row.uid)}
              style={{ color: "#E53E3E" }}
            >
              <BsTrashFill />
            </button>
          </div>
        </div>
      </>
    );
  };

  const columns: unknown[] = [
    {
      dataField: "pseudo",
      text: "Pseudo",
      filter: textFilter(),
      formatter: pseudoFormatter,
    },
    {
      dataField: "email",
      text: "Email",
      filter: textFilter(),
    },
    {
      dataField: "phone",
      text: "Téléphone",
    },
    {
      dataField: "disabled",
      text: "Bloquer ?",
      formatter: (cell: string, row: UserInfos) => (
        <ToggleUserActive item={row} />
      ),
    },
    {
      dataField: "id",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: actionFormatter,
    },
  ];

  if (!user) {
    columns.splice(0, 0, {
      dataField: "code",
      text: "N°",
    });
  }

  return (
    <>
      {isLoading && <ListeUsersTableSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={page}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => "No data"}
            pagination={paginationFactory(DefaultPaginationOptions)}
            filter={filterFactory()}
          />
        </>
      )}
    </>
  );
}

export default ListeUsersTable;
