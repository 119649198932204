import { useEffect, useState } from "react";
import { useUser } from "../../../../redux/root";
import { useProBubbleZones } from "../../../../utils/data";
import { BubbleZoneDetailsOwner, MultiBubbleZoneDetailsOwner } from "../BubbleZoneOwner/BubbleZoneDetailsOwner";
import MessageEmptyBubblezone from "../BubbleZoneOwner/MessageEmptyBubblezone";

function DonneesClient() {
  const user = useUser();
  const [loading, setLoading] = useState(true);
  const bubblesZones = useProBubbleZones(user?.code);

  useEffect(() => {
    if (bubblesZones.length > 0) {
      setLoading(false);
    } else {
      const ti = setTimeout(() => setLoading(false), 8000);
      return () => {
        clearTimeout(ti);
      };
    }
  }, [bubblesZones.length]);

  if (bubblesZones.length > 0) {
    return <MultiBubbleZoneDetailsOwner items={bubblesZones} />;
  } else {
    return (
      <div className="dashboard-admin-component">
        <div className="container-dashboard-page bg-white p-3">
          <div className="admin-page-title-header-container">
            <div className="bubble-zone-dash-locataire-requetes-header">
              <h4 className="bubble-zone-dash-header-titre">Données</h4>
            </div>
          </div>
          <div className="col-left-dashboard col-left-dashboard-responsive">
            <div className="">
              <div className="row mb-4">
                <div className="col-lg-12 col-content-b2b-component mb-3">
                  <div className="content-col-left-b2b-component">
                    <div className="row">
                      <div className="col-md-12">
                        {!loading && <MessageEmptyBubblezone />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DonneesClient;
