import { useEffect, useState } from "react";
import { BiSearchAlt2 } from "react-icons/bi";
import { IoMdAdd } from "react-icons/io";
import { NavLink } from "react-router-dom";
import { BubbleZone } from "../../../../../types";
import { useUser } from "../../../../redux/root";
import { useProBubbleZones, useScores } from "../../../../utils/data";
import {
  DisplayScores,
  DisplaySingleScores,
} from "../../Admin/Dashboard/StepNewDashboardV2/StatistiqueGeneralesV2";
import BubbleMap from "./BubbleMap";
import "./BubbleZoneOwner.css";
import MessageEmptyBubblezone from "./MessageEmptyBubblezone";

export function useFilterBubbleZones() {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const user = useUser();
  const [filtred, setFiltred] = useState<BubbleZone[]>([]);
  const bubbleZones = useProBubbleZones(user?.code);

  useEffect(() => {
    const l = search.trim().toLocaleLowerCase();
    if (l.length > 1) {
      setFiltred(
        bubbleZones.filter((f) =>
          `${f.name} ${f.phone} ${f.code}`.toLocaleLowerCase().includes(l)
        )
      );
    } else {
      setFiltred(bubbleZones);
    }
  }, [bubbleZones, search]);

  useEffect(() => {
    if (bubbleZones.length > 0) {
      setLoading(false);
    } else {
      const ti = setTimeout(() => setLoading(false), 8000);
      return () => {
        clearTimeout(ti);
      };
    }
  }, [bubbleZones.length]);

  return {
    search,
    setSearch,
    filtred,
    bubbleZones,
    loading,
  };
}

const BubbleZoneOwner = () => {
  const { bubbleZones, filtred, search, setSearch, loading } =
    useFilterBubbleZones();
  const scores = useScores();
  const isEmpty = bubbleZones.length === 0;

  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page bg-white p-3">
        <div className="admin-page-title-header-container">
          <div className="bubble-zone-dash-locataire-requetes-header">
            <h4 className="bubble-zone-dash-header-titre">Mes bubblezones</h4>
          </div>
        </div>
        <div className="col-left-dashboard col-left-dashboard-responsive">
          <div className="">
            {isEmpty && <SearchInput {...{ search, setSearch }} />}

            <div className="row mb-4">
              <div className="col-lg-8 col-content-b2b-component mb-3">
                <div className="content-col-left-b2b-component">
                  <div className="row">
                    <div className="col-md-12">
                      {!isEmpty && <BubbleMap data={filtred} />}
                      {isEmpty && !loading && <MessageEmptyBubblezone />}
                    </div>
                  </div>
                </div>
              </div>
              <DisplayScores
                showns={bubbleZones.map((b) => b.id)}
                data={scores}
              />
            </div>
            <DisplaySingleScores data={scores} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BubbleZoneOwner;

function SearchInput({
  search,
  setSearch,
}: {
  search: string;
  setSearch: (v: string) => void;
}) {
  return (
    <div className="flex-sb align-items-center mb-4">
      <div className="tab-dash-admin-filter-container w-100">
        <form className="row g-3 tab-dash-admin-filter-form">
          <div className="col-md-12 custom-filter-input-btn-add-container">
            <div className="custom-proprietaire-filter-input-container">
              <input
                type="text"
                className="form-control custom-proprietaire-filter-input"
                id="filtreLogement"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Recherchez un nom de l’établissement, le code de l’établissement ou le code du gérant"
              />
              <span className="custom-proprietaire-filter-input-icon-container">
                <BiSearchAlt2 className="custom-proprietaire-filter-input-icon" />
              </span>
            </div>
            <div className="content-btn-add-theme">
              <NavLink
                to="/proprietaire/assistance"
                className="btn custom-btn-add-theme"
              >
                <IoMdAdd />
                <span className="ps-2">Ajouter une bubblezone</span>
              </NavLink>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
