import { BiSearchAlt2 } from "react-icons/bi";
import { useFilterBubbleZones } from "./BubbleZoneOwner";
import BubbleZoneTableModifier from "./BuubleZoneTable/BubbleZoneOwnerModifierTable";
import MessageEmptyBubblezone from "./MessageEmptyBubblezone";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React from "react";

function ModifierBubbleZoneOwner() {
  const { bubbleZones, filtred, search, setSearch } = useFilterBubbleZones();
  const isEmpty = bubbleZones.length === 0;

  const [startDate, setStartDate] = React.useState(new Date());

  return (
    <div className="dashboard-admin-component pb-5">
      <div className="container-dashboard-page bg-white p-3 mb-3">
        <div className="admin-page-title-header-container">
          <div className="bubble-zone-dash-locataire-requetes-header">
            <h4 className="bubble-zone-dash-header-titre">Mes bubblezones</h4>
          </div>
        </div>
        <div className="col-left-dashboard col-left-dashboard-responsive">
          <div className="">
            {!isEmpty && (
              <div className="flex-sb align-items-center mb-4">
                <div className="tab-dash-admin-filter-container w-100">
                  <form className="row g-3 tab-dash-admin-filter-form">
                    <div className="col-md-12 custom-filter-input-btn-add-container">
                      <div className="custom-proprietaire-filter-input-container">
                        <input
                          type="text"
                          className="form-control custom-proprietaire-filter-input custom-width-proprietaire-filter-input"
                          id="filtreLogement"
                          placeholder="Recherchez un nom de l’établissement, le code de l’établissement ou le code du gérant"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <span className="custom-proprietaire-filter-input-icon-container">
                          <BiSearchAlt2 className="custom-proprietaire-filter-input-icon" />
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
            <div className="row mb-4">
              <div className="col-lg-12 col-content-b2b-component mb-3">
                {!isEmpty && (
                  <div className="content-col-left-b2b-component">
                    <div className="row">
                      <div className="col-md-12">
                        <BubbleZoneTableModifier data={filtred} />
                      </div>
                    </div>
                  </div>
                )}
                {isEmpty && <MessageEmptyBubblezone />}
              </div>
              {/* <DisplayScores data={scores} /> */}
            </div>
            {/* <DisplaySingleScores data={scores} /> */}
          </div>
        </div>
      </div>
      {!isEmpty && (
        <div className="p-3 message-communication-wrapper">
          <div className="row">
            <div className="col-md-8 mb-3">
              <div className="message-communication-title mb-4">
                Votre communication est disponible à télécharger (mis à jour 01/MM/YYYY)
              </div>
              <div className="message-communication-label mb-2">Réseau social</div>
              <div className="row">
                <div className="col-md-6 mb-4">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    style={{ height: "46px" }}
                  >
                    <option selected>Choisir</option>
                    <option value="1">Facebook</option>
                    <option value="2">Instagram</option>
                    <option value="3">Tiktok</option>
                    <option value="4">Snapchat</option>
                  </select>
                </div>
                <div className="col-md-6 mb-4">
                  <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="form-select custom-input-date-select" />
                </div>
                <div className="col-md-9 m-auto my-4">
                  <div className="message-communication-downloadable-container py-1 px-3">
                    <div>
                      <div className="message-communication-downloadable-title">
                        Communication.pdf
                      </div>
                      <div className="message-communication-downloadable-size">
                        2 Mo
                      </div>
                    </div>
                    <button className="btn message-communication-downloadable-btn">
                      Télécharger
                    </button>
                  </div>
                </div>
                {/* <div className="col-md-3 mb-3"></div> */}
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="custom-communication-preview d-flex align-items-center justify-content-center h-100 w-100 m-auto rounded p-1">
              {/* <ReactDevicePreview
                device="galaxys5"
                color="black"
                scale="1"
                >
              </ReactDevicePreview> */}
                <iframe src="https://www.lcg.ufrj.br/nodejs/books/react-beginners-handbook" width="100%" height="220px" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ModifierBubbleZoneOwner;
